import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    h3 {
      font-size: 32px;
      font-weight: 600;
      margin-top: 0;
    }

    ul {
      li {
        padding: 24px 0;

        .title {
          display: block;
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }

        .subtitle {
          display: block;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: ${theme.palette.neutral[100]};
      height: 36px;
      width: 36px;
      border-radius: 36px;
    }

    .empty-state-wrapper {
      margin: 64px 32px;
    }
  `,
)
