import React from 'react'

import { withModal } from '@azos/shared'

import {
  ProposalProductModal,
  ProposalProductModalProps,
} from '../../ProposalProductModal'
import { ProposalPayment, ProposalPaymentProps } from './../../ProposalPayment'

type Props = ProposalProductModalProps & ProposalPaymentProps

const ProposalCardPayment: React.VFC<Props> = ({
  proposalId,
  open,
  ...rest
}) => {
  return (
    <ProposalProductModal {...rest} proposalId={proposalId} open={open}>
      {open && <ProposalPayment proposalId={proposalId} />}
    </ProposalProductModal>
  )
}

export default withModal(ProposalCardPayment)
