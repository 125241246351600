import React from 'react'

import { ProposalPaymentStatusContext } from '@presentation/providers/ProposalProvider'

const useProposalPaymentStatus = () => {
  const context = React.useContext(ProposalPaymentStatusContext)

  if (!context) {
    throw new Error(
      'useProposalPaymentStatus must be used within a ProposalPaymentStatusProvider',
    )
  }

  return context
}

export { useProposalPaymentStatus }
