import React from 'react'

import { Modal, ModalProps, withModal } from '@azos/shared'
import { BillingServiceSource } from '@domain/models'

import {
  ProposalChangePayment,
  ProposalChangePaymentProps,
} from '../../ProposalChangePayment'

type Props = ModalProps & ProposalChangePaymentProps

const CardChangeProposalStatus: React.VFC<Props> = ({
  onClose,
  open,
  id,
  payment,
}) => {
  return (
    <Modal variant="drawer" open={open} onClose={onClose}>
      <ProposalChangePayment
        id={id}
        payment={payment}
        onClose={onClose}
        billingServiceSource={BillingServiceSource.IUGU}
      />
    </Modal>
  )
}

export default withModal(CardChangeProposalStatus)
