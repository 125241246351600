import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  () => css`
    height: 100%;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 32px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 32px;
    }

    > div {
      flex: 1;
    }

    .proposal-edit-payment__radio-button {
      button {
        min-height: 64px;
      }

      label {
        display: flex;
      }
    }

    .label-option {
      display: flex;
      font-size: 16px;

      .credit-card {
        display: flex;
        flex-direction: column;

        > span {
          margin-top: 4px !important;
        }
      }

      [class^='icon-'] {
        margin-right: 12px;
        font-size: 25px;
      }
    }
  `,
)
