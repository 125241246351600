import React from 'react'

import { Modal, ModalProps, withModal } from '@azos/shared'
import { ContractType } from '@domain/models'
import { makeChangeExpiryDateService } from '@main/factories/services'

import { PaymentEditExpiry, PaymentEditExpiryProps } from '../../../Payments'

type Props = ModalProps &
  Omit<PaymentEditExpiryProps, 'changeExpiryDateService' | 'proposalId'>

const changeExpiryDateService = makeChangeExpiryDateService()

const ProposalCardEditPaymentExpiry: React.VFC<Props> = ({
  onClose,
  open,
  id,
  days,
  onSuccess,
  billingService,
}) => {
  return (
    <Modal
      variant="bottomDrawer"
      title="Data de vencimento"
      subtitle="A nova data de vencimento será válida apenas para o próximo pagamento."
      open={open}
      onClose={onClose}
    >
      <PaymentEditExpiry
        id={id}
        days={days}
        type={ContractType.proposal}
        onSuccess={onSuccess}
        changeExpiryDateService={changeExpiryDateService}
        billingService={billingService}
        proposalId={id}
      />
    </Modal>
  )
}

export default withModal(ProposalCardEditPaymentExpiry)
