import React from 'react'

import { ProposalPaymentStatusProvider } from './'

export const withProposalPaymentStatus = <T extends object>(
  WrappedComponent: React.VFC<T>,
) =>
  function Component(props: T) {
    return (
      <ProposalPaymentStatusProvider>
        <WrappedComponent {...props} />
      </ProposalPaymentStatusProvider>
    )
  }

export default withProposalPaymentStatus
