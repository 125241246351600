import React from 'react'

import analytics from '@azos/analytics'
import { ViewPoliciesDetailsType } from '@azos/analytics/src/domain/models'
import { PaymentMethod } from '@azos/core'
import { EmptyState, Spinner, useMediaQuery } from '@azos/shared'
import { BillingServiceSource } from '@domain/models'
import Alert from '@presentation/components/atoms/Alert'
import { useFetchProposalDetails } from '@presentation/hooks/useFetchProposalDetails'

import { PaymentHistory } from '../../Payments'
import { ProposalPaymentDetails } from '../ProposalPaymentDetails'
import { ProposalPaymentProps } from './ProposalPayment.props'
import { Root } from './ProposalPayment.styles'

const ProposalPayment: React.VFC<ProposalPaymentProps> = ({ proposalId }) => {
  const {
    data: proposalDetails,
    isLoading,
    isError,
  } = useFetchProposalDetails(proposalId)

  const { isMobile } = useMediaQuery()

  const hasInvoices = React.useMemo(
    () => proposalDetails && proposalDetails.invoices?.length,
    [proposalDetails],
  )

  const isDisabledPaymentActions = React.useMemo(
    () =>
      proposalDetails?.proposal.billingServiceSource ===
      BillingServiceSource.VINDI,
    [proposalDetails?.proposal.billingServiceSource],
  )

  React.useEffect(() => {
    analytics.dashboard.policies.viewPoliciesDetails.execute({
      type: ViewPoliciesDetailsType.PROPOSALS,
    })
  }, [])

  return (
    <Root
      $hasPaymentMethod={
        proposalDetails?.payments.paymentMethod === PaymentMethod.CREDIT_CARD
      }
      $isLoadingOrError={(!hasInvoices && isError) || isLoading}
      $background={isMobile}
    >
      {isError && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar pagamento"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}

      {isLoading && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon={<Spinner />}
            title="Carregando pagamento"
          >
            Estamos carregando as informações de pagamento.
          </EmptyState>
        </div>
      )}

      {!!proposalDetails && (
        <>
          {proposalDetails.proposal.status.type !== 'success' && (
            <div className="alert-wrapper">
              <Alert>
                Existe uma fatura aberta. Só é possível alterar o pagamento após
                o pagamento da fatura.
              </Alert>
            </div>
          )}

          <ProposalPaymentDetails
            billingValue={proposalDetails.billing.value}
            disabledPaymentActions={isDisabledPaymentActions}
            payment={proposalDetails.payments}
            billingServiceSource={proposalDetails.proposal.billingServiceSource}
            invoices={proposalDetails.invoices}
            proposalId={proposalId}
            status={proposalDetails.proposal.status}
          />

          {hasInvoices ? (
            <div className="history">
              <PaymentHistory invoices={proposalDetails.invoices} />
            </div>
          ) : (
            <div className="empty-state-wrapper error">
              <EmptyState
                align="center"
                icon="icon-policy-cancel"
                iconColor="error"
                title="Erro ao carregar faturas"
              >
                Por favor, tente novamente ou entre em contato com nosso
                suporte.
              </EmptyState>
            </div>
          )}
        </>
      )}
    </Root>
  )
}

export default ProposalPayment
