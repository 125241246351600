import React from 'react'

import { Modal, withModal } from '@azos/shared'
import { Badge } from '@azos/shared/src/components/v2'
import ProductItem from '@presentation/components/atoms/ProductItem'
import { useFetchProposalDetails } from '@presentation/hooks/useFetchProposalDetails'

import { ProposalProductModalProps } from './ProposalProductModal.props'
import { Root } from './ProposalProductModal.styles'

const ProposalProductModal: React.FCC<ProposalProductModalProps> = ({
  children,
  proposalId,
  open,
  onClose,
}) => {
  const {
    data: proposalDetails,
    isLoading,
    isError,
  } = useFetchProposalDetails(proposalId)

  return (
    <Modal open={open} onClose={onClose} showBackButton variant="drawer">
      {!isLoading && !isError ? (
        <Root>
          <Badge color={proposalDetails?.proposal.status.color}>
            {proposalDetails?.proposal.status.label}
          </Badge>

          <h3>Proposta: {proposalDetails?.proposal.proposalNumber}</h3>

          <ul className="products">
            {proposalDetails?.proposal.coverages.map(coverage => (
              <li key={coverage.label}>
                <ProductItem>{coverage.label}</ProductItem>
              </li>
            ))}
          </ul>

          {children}
        </Root>
      ) : (
        children
      )}
    </Modal>
  )
}

export default withModal<ProposalProductModalProps>(ProposalProductModal)
