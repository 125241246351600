import React from 'react'

import { ProposalPaymentStatusContextData } from './ProposalPaymentStatusProvider.props'

export const ProposalPaymentStatusContext = React.createContext(
  {} as ProposalPaymentStatusContextData,
)

const ProposalPaymentStatusProvider: React.FCC = ({ children }) => {
  const [isPaymentChangeSucceeded, setIsPaymentChangeSucceeded] =
    React.useState(false)

  const onPaymentChangeSuccess = () => {
    setIsPaymentChangeSucceeded(true)
  }

  return (
    <ProposalPaymentStatusContext.Provider
      value={{
        isPaymentChangeSucceeded,
        setIsPaymentChangeSucceeded,
        onPaymentChangeSuccess,
      }}
    >
      {children}
    </ProposalPaymentStatusContext.Provider>
  )
}

export default ProposalPaymentStatusProvider
