import { SWRService, useFetch } from '@azos/core'
import { LoadProposalByIdUseCase } from '@domain/usecases'
import { makeLoadProposalDetailsService } from '@main/factories/services'

const loadProposalDetailsService = makeLoadProposalDetailsService()

export const useFetchProposalDetails = (proposalId: string) => {
  const { data = null, error } = useFetch<LoadProposalByIdUseCase.Response>(
    new SWRService(loadProposalDetailsService.getUrl({ proposalId }), () => {
      return loadProposalDetailsService.execute({
        proposalId,
      })
    }),
    { revalidateOnFocus: false },
  )

  const isLoading = !data && !error

  return {
    data,
    isLoading,
    isError: error,
  }
}
