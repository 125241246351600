import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .proposals-container {
      &__redirect {
        & > .button-text {
          display: flex;
          align-items: center;
          color: ${theme.palette.primary.main};
        }

        &-text {
          color: ${theme.palette.primary.main};
          text-decoration: underline;
          white-space: nowrap;
        }

        i {
          margin-left: ${theme.spacing[2]}px;
          font-size: 1.5rem;
        }
      }

      &__empty-state {
        background: ${theme.palette.neutral[50]};
        padding: ${theme.spacing[7]}px;

        h5 {
          color: ${theme.palette.neutral[400]};
        }

        &--content {
          display: grid;
          place-items: center;
          gap: ${theme.spacing[3]}px;
          color: ${theme.palette.neutral[400]};
          font-size: 14px;
        }
      }

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 58px;
      }

      &__proposal-count {
        color: ${theme.palette.neutral[300]};
        font-weight: ${theme.typography.fontWeightBold};
      }
    }

    .proposals-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1 0 100%;
    }
  `,
)

export default Root
