import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import { useRemoteConfig } from '@azos/core'
import { EmptyState, useTheme } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { withProposalPaymentStatus } from '@presentation/providers/ProposalProvider'
import { useRouter } from '@presentation/providers/RouterProvider'
import { env } from 'main/config/env'
import router from 'next/router'

import { ProposalCard } from '../ProposalCard'
import { ProposalsListProps } from './ProposalsList.props'
import { Root } from './ProposalsList.styles'

const ProposalsList: React.VFC<ProposalsListProps> = ({
  proposals,
  max,
  showHeader = false,
}) => {
  const remoteConfig = useRemoteConfig()
  const theme = useTheme()
  const Router = useRouter()
  const { user } = useAuth()

  const policiesTitle = React.useMemo(() => {
    const amount = proposals.length

    const s = proposals.length !== 1

    if (amount === 0) {
      return (
        <>
          <strong>Propostas</strong>
          <span className="proposals-container__proposal-count">
            ・ Nenhuma proposta enviada
          </span>
        </>
      )
    }

    return (
      <>
        Sua{s && 's'} <strong>proposta{s && 's'}</strong>
        <span className="proposals-container__proposal-count">
          ・ {amount} enviada{s && 's'}
        </span>
      </>
    )
  }, [proposals])

  const proposalsList = React.useMemo(() => {
    if (max && max > 0) {
      return proposals.slice(0, max)
    }

    return proposals
  }, [proposals, max])

  const handleRedirect = async () => {
    await Router.push('/minhas-apolices')
  }

  const handleSimulation = () => {
    if (
      remoteConfig.get('B2C_UPSELL') &&
      user &&
      user.insuredUser &&
      user.insuredUser?.info?.amountPolicies >= 1
    ) {
      router.push('/simular-novo-seguro')
      return
    }

    router.push(env.SIMULATION_URL)
  }

  const ProposalHeader = React.memo(function policyHeader() {
    return (
      <div className="proposals-container__info">
        <Text variant="body2">{policiesTitle}</Text>
        {max && proposals.length > 0 && proposals.length > max && (
          <Button
            variant="tertiary"
            onClick={handleRedirect}
            className="proposals-container__redirect"
          >
            <Typography
              variant="bodyBold3"
              className="proposals-container__redirect-text"
            >
              Ver tudo
            </Typography>
            <i className="icon-arrow-topright" />
          </Button>
        )}
      </div>
    )
  })

  const ProposalContent = React.memo(function proposalContent() {
    if (proposals.length === 0) {
      return (
        <div className="proposals-container__empty-state">
          <EmptyState
            align="center"
            icon="icon-proposal"
            iconColor={theme.palette.neutral[400]}
            title="Nenhuma proposta"
            titleTag="h5"
          >
            <div className="proposals-container__empty-state--content">
              No momento, você não possui nenhum proposta enviada. <br />
              Faça uma nova simulação.
              <Button onClick={handleSimulation}>Simule agora</Button>
            </div>
          </EmptyState>
        </div>
      )
    }

    return (
      <div className="proposals-wrapper">
        {proposalsList.map(proposal => (
          <ProposalCard
            key={proposal.proposalId}
            coverages={proposal.coverages}
            created={proposal.created}
            number={proposal.number}
            premium={proposal.premium}
            proposalId={proposal.proposalId}
            status={proposal.status}
            url={proposal.url}
            frequency={proposal.frequency}
          />
        ))}
      </div>
    )
  })

  return (
    <Root>
      {showHeader && <ProposalHeader />}
      <ProposalContent />
    </Root>
  )
}

export default withProposalPaymentStatus(ProposalsList)
