import React from 'react'

import { PaymentMethod } from '@azos/core'
import { Link } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { BillingServiceSource } from '@domain/models'
import { azosPhone } from '@main/config/contact'
import { makeListPossibleExpiryDatesService } from '@main/factories/services'
import { usePayment } from '@presentation/providers/PaymentProvider'
import dayjs from 'dayjs'
import Image from 'next/image'

import * as creditCardIcons from '../../../../assets/icons/creditCard'
import {
  ProposalCardEditPayment,
  ProposalCardEditPaymentExpiry,
} from '../ProposalChangePayment/components'
import { ProposalCardCreditCard } from './components'
import { CardProposalPaymentProps } from './ProposalPaymentDetails.props'
import { Root } from './ProposalPaymentDetails.styles'

const listPossibleExpiryDatesService = makeListPossibleExpiryDatesService()

const ProposalPaymentDetails: React.VFC<CardProposalPaymentProps> = ({
  status,
  proposalId,
  payment,
  billingValue,
  //
  disabledPaymentActions = false,
  billingServiceSource,
  invoices,
}) => {
  const { isPaymentChangeActive, isPaymentDateChangeActive } = usePayment()

  const [modalChangePayment, setModalChangePayment] = React.useState(false)
  const [modalChangeDatePayment, setModalChangeDatePayment] =
    React.useState(false)
  const [modalCreditCard, setModalCreditCard] = React.useState(false)

  const [days, setDays] = React.useState<string[]>([])
  const [expiresAt, setExpiresAt] = React.useState('')
  const [listExpiryDatesError, setListExpiryDatesError] = React.useState(false)

  const isCreditCard = React.useMemo(
    () => payment.paymentMethod === PaymentMethod.CREDIT_CARD,
    [payment],
  )

  const hasCreditCard = React.useMemo(
    () => !!payment.paymentInfo?.displayNumber,
    [payment],
  )

  const paymentInfo = React.useMemo(() => {
    if (isCreditCard && !hasCreditCard) {
      return {
        title: 'Cartão de crédito',
        icon: 'icon-credit-card',
      }
    }
    if (payment) {
      const info = {
        [PaymentMethod.BANK_SLIP]: { title: 'Boleto', icon: 'icon-boleto' },
        [PaymentMethod.PIX]: { title: 'Pix', icon: 'icon-pix' },
        [PaymentMethod.CREDIT_CARD]: {
          title: 'Cartão de crédito',
          icon: 'icon-credit-card',
        },
      }

      return info[payment.paymentMethod]
    }
  }, [payment, hasCreditCard, isCreditCard])

  const creditCardIcon = React.useMemo(() => {
    if (payment.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const icon = (creditCardIcons as any)[
        `${payment.paymentInfo?.brand?.toLowerCase()}`
      ]

      if (icon) return icon
    }

    return null
  }, [payment])

  const canShowPaymentChange =
    status.type === 'success' &&
    !disabledPaymentActions &&
    (!isCreditCard ||
      (isCreditCard && hasCreditCard) ||
      (isCreditCard && billingServiceSource === BillingServiceSource.VINDI)) &&
    isPaymentChangeActive

  const canShowPaymentDateChange =
    status.type === 'success' &&
    !disabledPaymentActions &&
    !listExpiryDatesError &&
    expiresAt !== 'invalid date' &&
    isPaymentDateChangeActive

  const canAddCreditCard =
    status.type === 'success' &&
    !disabledPaymentActions &&
    isCreditCard &&
    !hasCreditCard

  const isPaymentUnavailable =
    !canShowPaymentChange &&
    !canShowPaymentDateChange &&
    status?.type === 'success'

  const getExpiresAtFromLastInvoice = React.useCallback(() => {
    if (!invoices || !invoices.length) return ''

    const lastInvoice = invoices[invoices.length - 1]

    const dateParts = (lastInvoice.dueDate as string).split('/')
    const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])

    return dateObject
  }, [invoices])

  React.useEffect(() => {
    const getDays = async () => {
      setListExpiryDatesError(false)

      try {
        let expiresAt: string | Date = ''
        const response = await listPossibleExpiryDatesService.execute({
          id: proposalId,
          proposalId,
          billingService: billingServiceSource,
        })

        if (!response.expiresAt) {
          expiresAt = getExpiresAtFromLastInvoice()
        } else {
          expiresAt = response.expiresAt
        }
        setDays(response.possibleDates)
        setExpiresAt(
          dayjs(expiresAt).locale('pt-br').format('DD [de] MMMM').toLowerCase(),
        )
      } catch (error) {
        setListExpiryDatesError(true)
      }
    }

    getDays()
  }, [billingServiceSource, getExpiresAtFromLastInvoice, proposalId])

  return (
    <Root $type={payment.paymentMethod} $hasCreditCardIcon={!!creditCardIcon}>
      <div className="payment-data-content">
        {payment.paymentMethod === PaymentMethod.CREDIT_CARD &&
          creditCardIcon && (
            <Image
              className="credit-card-brand"
              src={creditCardIcon}
              width={44}
              height={44}
            />
          )}

        <div className="content">
          <div className="content-title">
            {(payment.paymentMethod !== PaymentMethod.CREDIT_CARD ||
              !creditCardIcon) && <span className={paymentInfo?.icon} />}
            <h2>{paymentInfo?.title}</h2>
          </div>

          {hasCreditCard && <span>{payment.paymentInfo?.displayNumber}</span>}

          <p>
            Sua cobrança é de <strong>{billingValue}</strong>
          </p>

          {canShowPaymentChange && (
            <Button onClick={() => setModalChangePayment(true)} fullWidth>
              Alterar forma de pagamento
            </Button>
          )}

          {canShowPaymentDateChange && (
            <Button
              onClick={() => setModalChangeDatePayment(true)}
              fullWidth
              variant="tertiary"
            >
              Alterar data de vencimento
            </Button>
          )}

          {isPaymentUnavailable && (
            <>
              <div className="contact-content">
                <Link
                  href={`tel:+${azosPhone}`}
                  title="Ligar para central de atendimento"
                  textAlign="center"
                >
                  <i className="icon-dial-phone" /> Central de Atendimento
                </Link>
                <Link
                  href={`https://api.whatsapp.com/send?phone=${azosPhone}`}
                  title="Abrir Whatsapp"
                  textAlign="center"
                >
                  <i className="icon-whatsapp" /> Whatsapp
                </Link>
              </div>
            </>
          )}

          {canAddCreditCard && (
            <Button
              onClick={() => setModalCreditCard(true)}
              fullWidth
              variant="tertiary"
            >
              Adicionar cartão
            </Button>
          )}
        </div>
      </div>

      <ProposalCardEditPayment
        open={modalChangePayment}
        onClose={() => setModalChangePayment(false)}
        id={proposalId}
        payment={payment || null}
        billingServiceSource={billingServiceSource}
      />

      <ProposalCardEditPaymentExpiry
        id={proposalId}
        days={days}
        open={modalChangeDatePayment}
        onClose={() => setModalChangeDatePayment(false)}
        onSuccess={() => setModalChangeDatePayment(false)}
        billingService={billingServiceSource}
      />

      <ProposalCardCreditCard
        open={modalCreditCard}
        onClose={() => setModalCreditCard(false)}
        id={proposalId}
        billingServiceSource={billingServiceSource}
      />
    </Root>
  )
}

export default ProposalPaymentDetails
