import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;

    > span {
      max-width: max-content;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      color: ${theme.palette.neutral[500]};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 18px 0;
    }

    .products {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 0;
      margin: 0 0 24px 0;
      list-style: none;

      @media (min-width: ${theme.breakpoints.md}px) {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 0;
        margin: 0 0 8px 0;
        list-style: none;
      }
    }
  `,
)

export default Root
