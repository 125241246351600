import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background: ${theme.palette.neutral[50]};
    padding: 12px 28px 28px;
    transition: box-shadow 0.2s;

    &:hover {
      box-shadow: 0 12px 24px #00000010;
    }

    .proposal-card-product {
      &__premium {
        display: block;
        margin-top: 8px;
        font-size: 1.125rem;

        strong {
          display: inline-block;
          margin: 0 2px;
          font-size: 2rem;
        }
      }
    }

    .heading {
      border-bottom: 1px solid ${theme.palette.neutral[100]};
      padding-bottom: 32px;

      .topbar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        > span {
          margin-top: 16px;
          margin-right: 16px;
        }

        .date {
          display: flex;
          align-items: center;
          margin: 16px 0 0;
          font-size: 18px;

          > span {
            margin-left: 14px;
          }
        }
      }

      h3 {
        margin: 0;
        margin-top: 12px;
        margin-right: 24px;
        padding: 0;
        font-size: 24px;
        font-weight: 600;
        color: ${theme.palette.neutral[500]};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .products {
      padding: 0;
      margin: 0 0 18px;
      list-style: none;
      flex: 1;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid ${theme.palette.neutral[100]};
        font-size: 16px;

        span {
          white-space: nowrap;
        }

        strong {
          font-weight: 600;
          margin-right: 12px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .proposal-card-product {
        &__button {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${theme.palette.neutral[100]};
          border-radius: 50%;
          border: 0;
          width: 36px;
          height: 36px;
          font-size: 20px;
        }
      }

      & > div + div {
        margin-left: 16px;
      }
    }
  `,
)
