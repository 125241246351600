import React from 'react'

import { PaymentMethod } from '@azos/core'
import { ListAction, EmptyState, Spinner } from '@azos/shared'
import { makeListPossibleExpiryDatesService } from '@main/factories/services'

import {
  ProposalCardEditPayment,
  ProposalCardEditPaymentExpiry,
} from './components'
import { ProposalChangePaymentProps } from './ProposalChangePayment.props'
import { Root } from './ProposalChangePayment.styles'

import 'dayjs/locale/pt-br'

const listPossibleExpiryDatesService = makeListPossibleExpiryDatesService()

const ModalChangePayment: React.VFC<ProposalChangePaymentProps> = ({
  id,
  payment,
  onClose,
  billingServiceSource,
}) => {
  const [days, setDays] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const [modalEditPayment, setModalEditPayment] = React.useState(true)

  const [modalEditExpiry, setModalEditExpiry] = React.useState(false)

  const paymentInfo = React.useMemo(() => {
    if (payment?.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const desc = payment?.paymentInfo?.displayNumber
        ?.split(' ')
        .splice(-2)
        .join(' ')

      return `Cartão ${desc || 'de crédito'}`
    }

    if (payment?.paymentMethod === PaymentMethod.PIX) {
      return 'Pix'
    }

    return 'Boleto'
  }, [payment])

  const options = React.useMemo(() => {
    const opt = [
      {
        name: 'change-payment',
        label: (
          <>
            <span className="subtitle">Meio de pagamento</span>
            <span className="title">{paymentInfo}</span>
          </>
        ),
        icon: (
          <button>
            <span className="icon-chevron-right" />
          </button>
        ),
        onClick: () => setModalEditPayment(true),
      },
    ]

    return opt
  }, [paymentInfo])

  React.useEffect(() => {
    const getDays = async () => {
      setLoading(true)
      setError(false)

      try {
        const response = await listPossibleExpiryDatesService.execute({
          id,
          proposalId: id,
          billingService: billingServiceSource,
        })

        setDays(response.possibleDates)
      } catch (error) {
        setError(true)
      }

      setLoading(false)
    }

    getDays()

    setModalEditPayment(true)
  }, [billingServiceSource, id])

  const handleCloseModalEditPayment = () => {
    setModalEditPayment(false)

    onClose()
  }

  const handleEditExpireSuccess = (value: string) => {
    setModalEditExpiry(false)
  }

  return (
    <Root>
      <h3>Alterar pagamento</h3>

      {loading && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon={<Spinner />}
            title="Carregando informações"
          >
            Estamos carregando as informações de pagamento.
          </EmptyState>
        </div>
      )}

      {!loading && <ListAction name="payment-options" options={options} />}

      <ProposalCardEditPayment
        open={modalEditPayment}
        onClose={handleCloseModalEditPayment}
        id={id}
        payment={payment}
        billingServiceSource={billingServiceSource}
      />

      <ProposalCardEditPaymentExpiry
        id={id}
        days={days}
        open={modalEditExpiry}
        onClose={() => setModalEditExpiry(false)}
        onSuccess={handleEditExpireSuccess}
      />
    </Root>
  )
}

export default ModalChangePayment
