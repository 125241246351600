import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { Modal, ModalProps, useTheme, withModal } from '@azos/shared'
import {
  makeChangePaymentMethodService,
  makeChangePaymentMethodV2Service,
} from '@main/factories/services'
import { useProposalPaymentStatus } from '@presentation/hooks/useProposalPaymentStatus'

import {
  ProposalEditPayment,
  ProposalEditPaymentProps,
} from '../../ProposalEditPayment'

type Props = ModalProps &
  Omit<
    ProposalEditPaymentProps,
    | 'changePaymentMethodService'
    | 'onPaymentChangeSuccess'
    | 'onPaymentChangeError'
  >

const changePaymentMethodService = makeChangePaymentMethodService()
const changePaymentMethodV2Service = makeChangePaymentMethodV2Service()

const ProposalCardEditPayment: React.VFC<Props> = ({
  onClose,
  open,
  id,
  payment,
  billingServiceSource,
}) => {
  const theme = useTheme()
  const { onPaymentChangeSuccess } = useProposalPaymentStatus()
  const { addNotification } = useNotification()

  const handlePaymentChangeSuccess = () => {
    onPaymentChangeSuccess()

    addNotification({
      variant: 'success',
      description: 'Método de pagamento alterado com sucesso.',
    })
  }

  const handlePaymentChangeError = () => {
    addNotification({
      variant: 'error',
      description:
        'Falha ao alterar método de pagamento. Por favor, tente novamente ou entre em contato com nosso suporte.',
    })
  }

  return (
    <Modal
      variant="drawer"
      open={open}
      backgroundColor={theme.palette.neutral[100]}
      onClose={onClose}
    >
      <ProposalEditPayment
        id={id}
        payment={payment}
        onPaymentChangeError={handlePaymentChangeError}
        onPaymentChangeSuccess={handlePaymentChangeSuccess}
        changePaymentMethodService={changePaymentMethodService}
        changePaymentMethodV2Service={changePaymentMethodV2Service}
        billingServiceSource={billingServiceSource}
      />
    </Modal>
  )
}

export default withModal<Props>(ProposalCardEditPayment)
