import React from 'react'

import analytics from '@azos/analytics'
import { ClickPoliciesCardType } from '@azos/analytics/src/domain/models'
import { CheckoutPaymentFrequency } from '@azos/core'
import { Tooltip, Utils } from '@azos/shared'
import { Badge } from '@azos/shared/src/components/v2'
import { PolicyDetailsStatus } from '@domain/models'

import { ProposalCardProductProps } from './ProposalCardProduct.props'
import { Root } from './ProposalCardProduct.styles'

const ProposalCardProduct: React.VFC<ProposalCardProductProps> = ({
  status,
  coverages,
  premium,
  onClick,
  onPayment,
  frequency,
}) => {
  const handleOnPayment = (e: React.MouseEvent) => {
    e.stopPropagation()

    analytics.dashboard.policies.clickPoliciesCards.execute({
      type: ClickPoliciesCardType.PROPOSALS,
    })
    onPayment?.()
  }

  return (
    <Root onClick={onClick}>
      <div className="heading">
        <div className="topbar">
          <Badge
            type={(status.type as PolicyDetailsStatus) || 'warning'}
            borderRadius={4}
          >
            {status.label}
          </Badge>
        </div>

        <span className="proposal-card-product__premium">
          R$ <strong>{Utils.currency.format(premium, '')}</strong>/
          {frequency === CheckoutPaymentFrequency.MONTHLY ? 'mês' : 'ano'}
        </span>
      </div>

      <ul className="products">
        {coverages.map(coverage => (
          <li key={coverage.label}>
            <strong>{coverage.label}</strong>
            <span>{coverage.value}</span>
          </li>
        ))}
      </ul>

      <div className="footer">
        {onPayment && (
          <Tooltip placement="top" tooltipContent="Gerenciar pagamento">
            <button
              type="button"
              className="proposal-card-product__button"
              onClick={handleOnPayment}
            >
              <span className="icon-credit-card" />
            </button>
          </Tooltip>
        )}

        <Tooltip tooltipContent="Baixar proposta" placement="top">
          <button type="button" className="proposal-card-product__button">
            <span className="icon-downloads" />
          </button>
        </Tooltip>
      </div>
    </Root>
  )
}

export default ProposalCardProduct
